import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import FAQ from "components/faqs/SingleCol.js";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;

const Text = styled.div`
  ${tw`text-lg text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
    }
  }
`;

export default ({ headingText = "End User License Agreement (EULA)" }) => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <h1>MyVehicleReports.com End User License Agreement (EULA)</h1>
            <p>
              This End User License Agreement ("EULA") outlines the terms and conditions for your use of MyVehicleReports.com ("Website", "Service") and the information and services offered therein. By accessing or using the Website or Service, you agree to be bound by the terms of this EULA. If you do not agree to all the terms of this EULA, you are not authorized to use the Website or Service.
            </p>

            <h2>1. Account and Services</h2>
            <p>
              1.1 To access certain features of the Website, you may be required to create an account. You are responsible for maintaining the confidentiality of your account information, including your username and password. You are also fully responsible for all activities that occur under your account.
            </p>
            <p>
              1.2 MyVehicleReports.com offers various services, including vehicle history reports, VIN decoding, and vehicle recall checks. Some services may require a separate fee.
            </p>

            <h2>2. Information Accuracy and Disclaimer of Liability</h2>
            <p>
              2.1 MyVehicleReports.com strives to provide accurate and up-to-date information. However, we do not guarantee the accuracy, timeliness, or completeness of any information provided through the Website or Service. You rely on this information at your own risk.
            </p>
            <p>
              2.2 MyVehicleReports.com is not liable for any damages resulting from your use of the information provided, including any purchases you make in reliance on a report. You acknowledge that such information may not be sufficient for making informed decisions about a vehicle.
            </p>
            <p>
              2.3 Vehicle history reports are not a substitute for a professional inspection. We strongly recommend obtaining an independent inspection before purchasing a vehicle.
            </p>

            <h2>3. Age Requirement</h2>
            <p>
              3.1 You must be 13 years of age or older to use the Website or Service. Users under 18 need parental or guardian consent.
            </p>

            <h2>4. Termination</h2>
            <p>
              4.1 MyVehicleReports.com reserves the right to terminate your account or access to the Service at any time, with or without cause.
            </p>

            <h2>5. Intellectual Property</h2>
            <p>
              5.1 MyVehicleReports.com owns the intellectual property rights to the Website, Service, and all content therein. You cannot copy, modify, or distribute any part of the Website or Service without our written permission.
            </p>

            <h2>6. Third-Party Content</h2>
            <p>
              6.1 The Website may contain links to third-party websites or services. We are not responsible for the content or practices of any third-party websites or services.
            </p>

            <h2>7. No Refunds or Returns</h2>
            <p>
              7.1 Due to the nature of the digital services offered, MyVehicleReports.com does not offer refunds or returns. All purchases are final.
            </p>

            <h2>8. Governing Law</h2>
            <p>
              8.1 This EULA is governed by and construed in accordance with the laws of the State, without regard to its conflict of law provisions.
            </p>

            <h2>9. Changes to This EULA</h2>
            <p>
              9.1 We may revise this EULA at any time. Such changes are effective immediately upon posting the revised EULA on the Website. You are responsible for checking the EULA periodically for updates. Your continued use of the Website or Service following the posting of a revised EULA constitutes your acceptance of the revised EULA.
            </p>

            <h2>10. Entire Agreement</h2>
            <p>
              10.1 This EULA constitutes the entire agreement between you and MyVehicleReports.com regarding your use of the Website and Service.
            </p>

            <h2>11. Contact Us</h2>
            <p>
              If you have any questions regarding this EULA, please contact us at <a href="mailto:Info@myvehiclereports.com">Info@myvehiclereports.com</a>.
            </p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
