import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import FAQ from "components/faqs/SingleCol.js";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;

const Text = styled.div`
  ${tw`text-lg text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
    }
  }
`;

export default ({ headingText = "Privacy Policy" }) => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <h1>MyVehicleReports.com Privacy Policy</h1>
            <p>
              This Privacy Policy describes how MyVehicleReports.com ("we", "us", or "our") collects, uses, and discloses your information when you use our website ("Website") or services ("Service").
            </p>

            <h2>Information We Collect</h2>
            <p>We collect the following types of information:</p>
            <ul>
              <li>
                <strong>Account Information:</strong> When you create an account, we collect your username, password (hashed and salted for security), and any other information you choose to provide, such as your email address.
              </li>
              <li>
                <strong>Usage Information:</strong> We collect information about your use of the Website and Service, such as the pages you visit, the reports you generate, and the searches you perform.
              </li>
              <li>
                <strong>Device Information:</strong> We collect information about the device you use to access the Website or Service, such as your IP address, device type, operating system, and browser type.
              </li>
            </ul>

            <h2>How We Use Your Information</h2>
            <p>We use your information for the following purposes:</p>
            <ul>
              <li>To provide you with the Website and Service;</li>
              <li>To process your transactions;</li>
              <li>To communicate with you about your account and the Website or Service;</li>
              <li>To personalize your experience on the Website or Service;</li>
              <li>To improve the Website and Service;</li>
              <li>To comply with legal and regulatory requirements;</li>
            </ul>

            <h2>Sharing Your Information</h2>
            <p>
              We may share your information with third-party service providers who help us operate the Website and Service. These service providers are contractually obligated to keep your information confidential and to use it only for the purposes authorized by us.
            </p>
            <p>
              We will not share your information with any third-party for marketing purposes without your consent.
            </p>

            <h2>Data Security</h2>
            <p>
              We take reasonable steps to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no website or internet transmission is completely secure. We cannot guarantee the security of your information.
            </p>

            <h2>Your Choices</h2>
            <p>
              You can access and update your account information at any time by logging into your account. You can also opt out of receiving marketing communications from us by following the unsubscribe instructions in those communications.
            </p>

            <h2>Children's Privacy</h2>
            <p>
              The Website and Service are not directed to children under the age of 13. We do not knowingly collect information from children under the age of 13. If you are a parent or guardian and you believe your child has provided us with information, please contact us. We will delete any information we collect from a child under the age of 13.
            </p>

            <h2>Changes to This Privacy Policy</h2>
            <p>
              We may revise this Privacy Policy at any time. Such changes are effective immediately upon posting the revised Privacy Policy on the Website. You are responsible for checking the Privacy Policy periodically for updates. Your continued use of the Website or Service following the posting of a revised Privacy Policy constitutes your acceptance of the revised Privacy Policy.
            </p>

            <h2>Contact Us</h2>
            <p>
              If you have any questions regarding this Privacy Policy, please contact us at <a href="mailto:info@myvehiclereports.com">info@myvehiclereports.com</a>.
            </p>
          </Text>
        </ContentWithPaddingXl>
      </Container>


      <Footer />
    </AnimationRevealPage>
  );
};
